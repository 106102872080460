import React from 'react';

/* This example requires Tailwind CSS v2.0+ */

export default function LogoScroll({ text, logos }) {
  return (
    <div>
      <div className="max-w-7xl mx-auto">
        <h2 className="text-3xl ml-4 mb-2 font-extrabold text-black">{text}</h2>
        <div className="relative h-16 flex overflow-hidden">
          <div className="absolute flex w-[100%] flex-wrap justify-around">
            {logos.map((logo) => {
              return (
                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                  <img className="h-12" src={logo.src} alt="Tuple" />
                </div>
              );
            })}
          </div>
          <div className="flex top-0 w-[100%] absolute flex-wrap justify-around">
            {logos.map((logo) => {
              return (
                <div className="mt-4 ml-8 flex flex-grow flex-shrink-0 lg:flex-grow-0 lg:ml-4">
                  <img className="h-12" src={logo.src} alt="Tuple" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
