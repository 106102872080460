import React, { Component } from 'react';

import './index.css';

export class ReliablePanel extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component reliable-panel">
        <h2>The lodging reservation software you can depend on</h2>
        <div className="reliable-panel-metrics">
          <div className="reliable-panel-metric">
            Over <span className="metric">1,800</span> businesses
          </div>
          <div className="reliable-panel-metric">
            Located in <span className="metric">48</span> states
          </div>
          <div className="reliable-panel-metric">
            Over <span className="metric">5,000,000</span> happy guests every
            year
          </div>
        </div>
        <div className="reliable-panel-actions">
          <a className="request-a-demo" href="/request-a-demo">
            Request a demo
          </a>
        </div>
      </div>
    );
  }
}

export default ReliablePanel;
