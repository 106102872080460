import React, { Component } from 'react';

import Hero from '../../../../../ui-library/Hero';

export const HomePageHero = ({ heroImage }) => {
  return (
    <Hero
      image={heroImage}
      headline="Get more direct bookings, save time and focus on your guests."
      subheadline="Increase productivity and revenue with our booking engine, channel manager and property management system."
    />
  );
};
export default HomePageHero;
