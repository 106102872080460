import React from 'react';

import HomePage from '../components/pages/general/HomePage';

export default ({ data }) => (
  <HomePage heroImage={data.file.childImageSharp.fluid} />
);

export const query = graphql`
  query {
    file(relativePath: { eq: "hero-image.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
