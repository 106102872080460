import React, { Component } from 'react';

import './index.css';

import expediaLogo from './images/logo-expedia-white.svg';
import airbnbLogo from './images/logo-airbnb-white.svg';
import bookingComLogo from './images/logo-booking-white.svg';
import tripadvisorLogo from './images/logo-tripadvisor-white.svg';

export class ChannelPanel extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component channel-panel">
        <h2>Direct connectivity to the channels you need</h2>
        <div className="channel-panel-list">
          <span className="channel-panel-list-row-1">
            <img
              src={expediaLogo}
              className="expedia h-[43px] w-[153px] inline"
              width="153"
              height="43"
              alt="Expedia"
            />
            <img
              className="booking-com h-[33px] w-[196px] inline"
              src={bookingComLogo}
              width="196"
              height="33"
              alt="Booking.com"
            />
          </span>
          <span className="channel-panel-list-row-2">
            <img
              className="airbnb h-[89px] w-[78px] inline"
              src={airbnbLogo}
              width="78"
              height="89"
              alt="Airbnb"
            />
            <img
              className="tripadvisor h-[32px] w-[211px] inline"
              src={tripadvisorLogo}
              width="211"
              height="32"
              alt="TripAdvisor"
            />
          </span>
        </div>
        <div className="channel-panel-features">
          <div className="channel-panel-feature">
            <div className="channel-panel-feature-header">
              Real time synchronization
            </div>
            <div className="channel-panel-feature-description">
              We connect you to dozens of Online Travel Agencies to keep
              everything synchronized for you.
            </div>
          </div>
          <div className="channel-panel-feature eliminate-double-bookings">
            <div className="channel-panel-feature-header">
              Eliminate double bookings
            </div>
            <div className="channel-panel-feature-description">
              Our hotel reservation system synchronizes reservations within
              seconds to make sure you don't experience double bookings.
            </div>
          </div>
          <div className="channel-panel-feature">
            <div className="channel-panel-feature-header">
              Automatic rate adjustments
            </div>
            <div className="channel-panel-feature-description">
              Take advantage of our rate adjustment features that allow you to
              change pricing across all channels based on specific rules you
              define.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChannelPanel;
