import React, { Component } from 'react';
import Link from '../../core/Link';
import Img from 'gatsby-image/withIEPolyfill';

/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/

import useMedia from '../../../utils/useMedia';

export default function Hero({ image, headline, subheadline }) {
  const mediaSize = useMedia(['(min-width: 2000px)'], ['contain'], 'cover');
  return (
    <div className="bg-gradient-to-r from-[#0098ce] to-[#4bbbdf] pb-8 sm:pb-12 lg:pb-12">
      <div className="pt-8 overflow-hidden sm:pt-12 lg:relative lg:py-48">
        <div className="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <div className="mt-20">
              <div>
                {/* <a href="#" className="inline-flex space-x-4">
                  <span className="rounded bg-indigo-50 px-2.5 py-1 text-xs font-semibold text-indigo-600 tracking-wide uppercase">
                    What's new
                  </span>
                  <span className="inline-flex items-center text-sm font-medium text-indigo-600 space-x-1">
                    <span>Just shipped version 0.1.0</span>
                    <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                </a> */}
              </div>
              <div className="mt-6 sm:max-w-xl">
                <h1 className="text-4xl font-extrabold text-slate-50 tracking-tight sm:text-5xl">
                  {headline}
                </h1>
                <p className="mt-2 text-xl text-slate-200">{subheadline}</p>
              </div>
              <div className="max-w-sm">
                <Link to="/request-a-demo">
                  <button
                    className="block w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                    type="submit"
                  >
                    Request a demo today
                  </button>
                </Link>
              </div>

              <div className="-mt-2 relative max-w-sm">
                <div className="gap-x-2 rounded-md p-4 items-center text-slate-200 bg-slate-900 opacity-5 h-10" />
                <a
                  className="flex gap-x-2 items-center text-slate-200 absolute inset-0"
                  href="https://www.capterra.com/reviews/182891/ThinkReservations?utm_source=vendor&utm_medium=badge&utm_campaign=capterra_reviews_badge"
                >
                  {' '}
                  <img
                    border="0"
                    className="w-28"
                    src="https://assets.capterra.com/badge/b48a147fe853f6eee1809596bc2f93f1.svg?v=2127972&p=182891"
                  />{' '}
                  by all of our happy customers.
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div className="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div className="hidden opacity-10 sm:block">
              <svg
                className="absolute top-8 right-1/2 -mr-3 lg:m-0 lg:left-0"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="837c3e70-6c3a-44e6-8854-cc48c737b659"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#837c3e70-6c3a-44e6-8854-cc48c737b659)"
                />
              </svg>
            </div>
            <div className="relative pl-4 -mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <Img
                className="w-full lg:h-full lg:w-auto lg:max-w-none"
                fluid={image}
                objectFit={mediaSize}
                objectPosition="left bottom"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
