import React, { Component } from 'react';

import './index.css';

import teamImage from './images/TR-Group1-web.jpg';

export class SupportPanel extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component support-panel">
        <h2>Superior customer support</h2>
        <div className="support-panel-image">
          <img src={teamImage} alt="" />
        </div>
        <div className="support-panel-features">
          <div className="support-panel-feature phone">
            <div className="support-panel-feature-title">
              Phone and email support
            </div>
            <div className="support-panel-feature-description">
              We provide world class support to make sure you can stay
              successful.
            </div>
          </div>
          <div className="support-panel-feature account-managers">
            <div className="support-panel-feature-title">
              Experienced account managers
            </div>
            <div className="support-panel-feature-description">
              Our account managers have industry experience and are happy to
              help!
            </div>
          </div>
          <div className="support-panel-feature videos">
            <div className="support-panel-feature-title">Video library</div>
            <div className="support-panel-feature-description">
              Our extensive video library makes it easy for you to learn
              features and best practices.
            </div>
          </div>
          <div className="support-panel-feature webinars">
            <div className="support-panel-feature-title">Live webinars</div>
            <div className="support-panel-feature-description">
              We provide live webinars on various topics to help our customers
              continue to grow.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SupportPanel;
