import React, { Component } from 'react';

import './index.css';

export class SetupProcessPanel extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component setup-process-panel">
        <div className="setup-process-panel-title">
          It's easy to get started!
        </div>
        <div className="setup-process-panel-subtitle">
          Our dedicated staff of professionals set up your system, giving you a
          seamless start
        </div>
        <div className="setup-process-panel-features">
          <div className="setup-process-panel-feature setup">
            <div className="setup-process-panel-feature-title">
              We configure your account for you
            </div>
            <div className="setup-process-panel-feature-description">
              You are set up for success right out of the gate
            </div>
          </div>
          <div className="setup-process-panel-feature import">
            <div className="setup-process-panel-feature-title">
              We import all your historical data
            </div>
            <div className="setup-process-panel-feature-description">
              Our goal is to make it easy for you to get started!
            </div>
          </div>
          <div className="setup-process-panel-feature training">
            <div className="setup-process-panel-feature-title">
              We will train you and your staff
            </div>
            <div className="setup-process-panel-feature-description">
              Our account managers are happy to help
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default SetupProcessPanel;
