import React, { Component } from 'react';
import FeatureList from '../../../../../core/FeatureList';

import './index.css';

import growthImage from './images/growth.svg';

export class DirectBookingsPanel extends Component {
  static propTypes = {};

  render() {
    return (
      <div className="component direct-bookings-panel">
        <h2>Increase direct bookings</h2>
        <div className="mobile-only growth-image">
          <img src={growthImage} alt="" />
        </div>
        <div className="direct-bookings-panel-body">
          <div className="direct-bookings-panel-body-feature-list">
            <FeatureList>
              <li>
                <div className="title">Receive more online reservations</div>
                <div className="description">
                  Businesses who upgrade to ThinkReservations report
                  experiencing a higher booking engine conversion rate. Our
                  reservation software makes it fast and simple for guests to
                  find what they want and confirm.
                </div>
              </li>
              <li>
                <div className="title">Upsell items and packages</div>
                <div className="description">
                  The ability to curate items and packages that meet the needs
                  of your guests leads to our customers bringing in 6-8 times
                  more upsell revenue.
                </div>
              </li>
              <li>
                <div className="title">Change pricing in real-time</div>
                <div className="description">
                  You’ll benefit from our Yield Management feature which enables
                  you to set automatic rate adjustments based on your supply and
                  demand. Never undersell a room again.
                </div>
              </li>
            </FeatureList>
          </div>
          <div className="direct-bookings-panel-body-image">
            <img src={growthImage} alt="" />
          </div>
        </div>
      </div>
    );
  }
}

export default DirectBookingsPanel;
