import React, { Component, Fragment } from 'react';
import Helmet from 'react-helmet';

import App from '../../../layout/App';
import IntroductionPanel from './components/IntroductionPanel';
import ProductPanel from './components/ProductPanel';
import ChannelPanel from './components/ChannelPanel';
import DirectBookingsPanel from './components/DirectBookingsPanel';
import SupportPanel from './components/SupportPanel';
import SetupProcessPanel from './components/SetupProcessPanel';
import ReliablePanel from './components/ReliablePanel';

import './index.css';
import LogoScroll from '../../../ui-library/LogoScroll';
import VideoCta from '../../../ui-library/VideoCta';
import Link from '../../../core/Link';
import TabbedFeatureCallout from '../../../ui-library/TabbedFeatureCallout';
import Testimonial from '../../../ui-library/Testimonials';
import FeatureHighlight from '../../../ui-library/FeatureHighlight';

import blisswoodLogo from '../../../../images/logos/blisswood-bed-breakfast-logo.png';
import heritageLogo from '../../../../images/logos/Heritage-house-transparent-crop.svg';
import threeFallsLogo from '../../../../images/logos/three-falls-cove.png';
import eightDyer from '../../../../images/logos/8dyer-transparent.svg';
import trailsideLogo from '../../../../images/logos/Trailside-transparent-crop.svg';

const logos = [
  // {
  //   src: blisswoodLogo,
  // },
  {
    src: heritageLogo,
  },
  {
    src: threeFallsLogo,
  },
  {
    src: eightDyer,
  },
  {
    src: trailsideLogo,
  },
];

export class HomePage extends Component {
  static propTypes = {};

  render() {
    return (
      <App>
        <div className="component home-page">
          <Helmet title="Reservation Management Software for Hotels, Inns, B&Bs & More">
            <meta
              name="description"
              content="The all-in-one property management system that streamlines your business helping you save time and increase revenue."
            />
            <script
              type="application/ld+json"
              children={JSON.stringify({
                '@context': 'http://schema.org',
                '@type': 'Organization',
                name: 'ThinkReservations',
                url: 'https://www.thinkreservations.com/',
                telephone: '877-736-4195 x1',
                address: {
                  '@type': 'PostalAddress',
                  streetAddress: '1700 7th Ave Ste 116 PMB 173',
                  addressLocality: 'Seattle',
                  addressRegion: 'WA',
                  postalCode: '98101',
                  addressCountry: {
                    '@type': 'Country',
                    name: 'USA',
                  },
                },
                sameAs: ['https://www.facebook.com/ThinkReservations/'],
              })}
            />
          </Helmet>

          <IntroductionPanel heroImage={this.props.heroImage} />
          <div className="pb-16"></div>
          <LogoScroll text="Trusted by" logos={logos} />
          <div className="pb-32"></div>

          <VideoCta
            videoId="588488363"
            headline={'The Experienced Team Dedicated to Your Success'}
            copy="We make managing your property easy so you can focus on what you do best - providing great hospitality! "
            mediaLeft={true}
            children={
              <Fragment>
                <Link to="/request-a-demo">
                  <button
                    className="block w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
                    type="submit"
                  >
                    Schedule a demo!
                  </button>
                </Link>
              </Fragment>
            }
          />
          <div className="pb-32"></div>
          {/* <LogoScroll text="Trusted by" /> */}
          {/* <div className="pb-32"></div> */}
          {/* <TabbedFeatureCallout /> */}
          {/* <div className="pb-32"></div> */}
          {/* <Testimonial /> */}
          {/* <div className="pb-32"></div> */}
          {/* <FeatureHighlight /> */}
          {/* <div className="pb-32"></div> */}
          <ProductPanel />
          <ChannelPanel />
          <DirectBookingsPanel />
          <SetupProcessPanel />
          <ReliablePanel />
          <SupportPanel />
        </div>
      </App>
    );
  }
}

export default HomePage;
