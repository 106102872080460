import React, { Fragment } from 'react';
import TwoColumnCta from '../TwoColumnCta';
import Link from '../../core/Link';
import Testimonial from '../Testimonials/Testimonial';

const DefaultCta = (
  <Fragment>
    <Link to="/request-a-demo">
      <button
        className="block w-full my-4 rounded-md border border-transparent px-5 py-3 bg-[#e27d4e] text-base font-medium text-white shadow focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 sm:px-10"
        type="submit"
      >
        Request a demo today
      </button>
    </Link>
  </Fragment>
);

const FeatureHighlight = ({
  headline = 'The Channel Manager That Keeps You in Control',
  copy = `You need a fast, real-time connection to give you peace of mind when it comes to partnering with OTAs. We built the channel manager that seamlessly synchronizes your availability, restrictions, and rates with sites including Expedia, Booking.com, Airbnb, Tripadvisor, and more.  Eliminate the need for a third-party product and say goodbye to double-bookings.`,
  children = DefaultCta,
  logo = 'https://tailwindui.com/img/logos/tuple-logo-indigo-300.svg',
  quote = `Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo
expedita voluptas culpa sapiente alias molestiae. Numquam corrupti
in laborum sed rerum et corporis.`,
  avatar = 'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  name = 'Judith Black',
  position = 'CEO, Tuple',
}) => {
  return (
    <TwoColumnCta
      // callout="Coming soon"
      headline={headline}
      copy={copy}
      children={children}
      Media={() => (
        <div className="bg-indigo-500 px-8">
          <Testimonial />
        </div>
      )}
    />
  );
};

export default FeatureHighlight;
